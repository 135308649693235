$flag-icons-path: '/images/flags' !default;

@mixin flag-icon($country) {
    .flag-icon-#{$country} {
        background-image: url(#{$flag-icons-path}/rectangle/#{$country}.svg);

        &.flag-icon-squared {
            background-image: url(#{$flag-icons-path}/square/#{$country}.svg);
        }
    }
}

.flag-icon-background {
    background-size: contain;
    background-position: 50%;
    background-repeat: no-repeat;
}

.flag-icon {
    @extend .flag-icon-background;
    display: inline-block;
    width: 2em;
    line-height: 2em;
    margin: 0.5em;

    &:before {
        content: '\00a0';
    }

    &.flag-icon-squared {
        width: 3em;
    }
}

// Base Flags (Commonly Used)
@include flag-icon(ro);
@include flag-icon(cs);
@include flag-icon(de);
@include flag-icon(en);
@include flag-icon(es);
@include flag-icon(fi);
@include flag-icon(fr);
@include flag-icon(nl);
@include flag-icon(pl);
@include flag-icon(pt);
@include flag-icon(se);
@include flag-icon(sk);
@include flag-icon(sl);
@include flag-icon(it);

// Fallback Flag
@include flag-icon(xx);

// Remaining Flags
@include flag-icon(ad);
@include flag-icon(ae);
@include flag-icon(af);
@include flag-icon(ag);
@include flag-icon(ai);
@include flag-icon(al);
@include flag-icon(am);
@include flag-icon(ao);
@include flag-icon(aq);
@include flag-icon(ar);
@include flag-icon(as);
@include flag-icon(at);
@include flag-icon(au);
@include flag-icon(aw);
@include flag-icon(ax);
@include flag-icon(az);
@include flag-icon(ba);
@include flag-icon(bb);
@include flag-icon(bd);
@include flag-icon(be);
@include flag-icon(bf);
@include flag-icon(bg);
@include flag-icon(bh);
@include flag-icon(bi);
@include flag-icon(bj);
@include flag-icon(bl);
@include flag-icon(bm);
@include flag-icon(bn);
@include flag-icon(bo);
@include flag-icon(bq);
@include flag-icon(br);
@include flag-icon(bs);
@include flag-icon(bt);
@include flag-icon(bv);
@include flag-icon(bw);
@include flag-icon(by);
@include flag-icon(bz);
@include flag-icon(ca);
@include flag-icon(cc);
@include flag-icon(cd);
@include flag-icon(cf);
@include flag-icon(cg);
@include flag-icon(ch);
@include flag-icon(ci);
@include flag-icon(ck);
@include flag-icon(cl);
@include flag-icon(cm);
@include flag-icon(cn);
@include flag-icon(co);
@include flag-icon(cr);
@include flag-icon(cu);
@include flag-icon(cv);
@include flag-icon(cw);
@include flag-icon(cx);
@include flag-icon(cy);
@include flag-icon(cz);
@include flag-icon(dj);
@include flag-icon(dk);
@include flag-icon(dm);
@include flag-icon(do);
@include flag-icon(dz);
@include flag-icon(ec);
@include flag-icon(ee);
@include flag-icon(eg);
@include flag-icon(eh);
@include flag-icon(er);
@include flag-icon(et);
@include flag-icon(fj);
@include flag-icon(fk);
@include flag-icon(fm);
@include flag-icon(fo);
@include flag-icon(ga);
@include flag-icon(gb);
@include flag-icon(gd);
@include flag-icon(ge);
@include flag-icon(gf);
@include flag-icon(gg);
@include flag-icon(gh);
@include flag-icon(gi);
@include flag-icon(gl);
@include flag-icon(gm);
@include flag-icon(gn);
@include flag-icon(gp);
@include flag-icon(gq);
@include flag-icon(gr);
@include flag-icon(gs);
@include flag-icon(gt);
@include flag-icon(gu);
@include flag-icon(gw);
@include flag-icon(gy);
@include flag-icon(hk);
@include flag-icon(hm);
@include flag-icon(hn);
@include flag-icon(hr);
@include flag-icon(ht);
@include flag-icon(hu);
@include flag-icon(id);
@include flag-icon(ie);
@include flag-icon(il);
@include flag-icon(im);
@include flag-icon(in);
@include flag-icon(io);
@include flag-icon(iq);
@include flag-icon(ir);
@include flag-icon(is);
@include flag-icon(je);
@include flag-icon(jm);
@include flag-icon(jo);
@include flag-icon(jp);
@include flag-icon(ke);
@include flag-icon(kg);
@include flag-icon(kh);
@include flag-icon(ki);
@include flag-icon(km);
@include flag-icon(kn);
@include flag-icon(kp);
@include flag-icon(kr);
@include flag-icon(kw);
@include flag-icon(ky);
@include flag-icon(kz);
@include flag-icon(la);
@include flag-icon(lb);
@include flag-icon(lc);
@include flag-icon(li);
@include flag-icon(lk);
@include flag-icon(lr);
@include flag-icon(ls);
@include flag-icon(lt);
@include flag-icon(lu);
@include flag-icon(lv);
@include flag-icon(ly);
@include flag-icon(ma);
@include flag-icon(mc);
@include flag-icon(md);
@include flag-icon(me);
@include flag-icon(mf);
@include flag-icon(mg);

// Other Flags (Organizations & Regions)
@include flag-icon(arab);
@include flag-icon(asean);
@include flag-icon(cefta);
@include flag-icon(cp);
@include flag-icon(dg);
@include flag-icon(eac);
@include flag-icon(es-ct);
@include flag-icon(es-ga);
@include flag-icon(es-pv);
@include flag-icon(eu);
@include flag-icon(gb-eng);
@include flag-icon(gb-nir);
@include flag-icon(gb-sct);
@include flag-icon(gb-wls);
@include flag-icon(ic);
@include flag-icon(pc);
@include flag-icon(sh-ac);
@include flag-icon(sh-hl);
@include flag-icon(sh-ta);
@include flag-icon(un);
@include flag-icon(xk);